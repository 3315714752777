// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wgmgavlakWt3EkdWzPV5 {
  margin: 0 auto;
  padding: 0;
  max-width: 500px;
  position: relative;
  height: 100vh;
  /* overflow-y: auto !important;
  overflow-x: hidden; */
  /* overflow: hidden;
  overflow-x: hidden; */
}

/* .wrapper > div {
  background: transparent;
} */

.EZvbHgWjGmuoAxNh82XA {
  /* height: 100vh; */
  /* overflow-y: auto !important;
  overflow-x: hidden; */
}

.WeTydePpPhaBu2t0_XbD {
  position: fixed;
  /* z-index: 50; */
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  max-width: 350px;
  width: 100%;
  padding: 13px 20px;
  border-radius: 30px 30px 0px 0px;
  justify-content: space-between;
  background: #2D2D2D;
}

.V1ToqHKL5PV0AvHxp0ZE > span {
  font-size: 10px;
  line-height: 120%;
  letter-spacing: 0.066px;
  font-weight: 510;
  color: #8E8E93;
}

.T5MdnY9mkZKK_9YpRtRL > span {
  color: #007AFF;
}

.T5MdnY9mkZKK_9YpRtRL > svg > path {
  fill: #007AFF;
}

@media (max-width: 375px) {
  .WeTydePpPhaBu2t0_XbD {
      max-width: 280px;
  }
}

@media (max-width: 345px) {
  .WeTydePpPhaBu2t0_XbD {
      max-width: 250px;
  }
}

main {
  padding: 0 16px 0 16px;
}


.hnB8R0R07BE_9mlYK5ja > main {
  padding: 0 16px !important;
}`, "",{"version":3,"sources":["webpack://./src/layout/layout.module.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,UAAU;EACV,gBAAgB;EAChB,kBAAkB;EAClB,aAAa;EACb;uBACqB;EACrB;uBACqB;AACvB;;AAEA;;GAEG;;AAEH;EACE,mBAAmB;EACnB;uBACqB;AACvB;;AAEA;EACE,eAAe;EACf,iBAAiB;EACjB,SAAS;EACT,SAAS;EACT,2BAA2B;EAC3B,gBAAgB;EAChB,WAAW;EACX,kBAAkB;EAClB,gCAAgC;EAChC,8BAA8B;EAC9B,mBAAmB;AACrB;;AAEA;EACE,eAAe;EACf,iBAAiB;EACjB,uBAAuB;EACvB,gBAAgB;EAChB,cAAc;AAChB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE;MACI,gBAAgB;EACpB;AACF;;AAEA;EACE;MACI,gBAAgB;EACpB;AACF;;AAEA;EACE,sBAAsB;AACxB;;;AAGA;EACE,0BAA0B;AAC5B","sourcesContent":[".wrapper {\n  margin: 0 auto;\n  padding: 0;\n  max-width: 500px;\n  position: relative;\n  height: 100vh;\n  /* overflow-y: auto !important;\n  overflow-x: hidden; */\n  /* overflow: hidden;\n  overflow-x: hidden; */\n}\n\n/* .wrapper > div {\n  background: transparent;\n} */\n\n.content {\n  /* height: 100vh; */\n  /* overflow-y: auto !important;\n  overflow-x: hidden; */\n}\n\n.actions {\n  position: fixed;\n  /* z-index: 50; */\n  bottom: 0;\n  left: 50%;\n  transform: translateX(-50%);\n  max-width: 350px;\n  width: 100%;\n  padding: 13px 20px;\n  border-radius: 30px 30px 0px 0px;\n  justify-content: space-between;\n  background: #2D2D2D;\n}\n\n.actionsItem > span {\n  font-size: 10px;\n  line-height: 120%;\n  letter-spacing: 0.066px;\n  font-weight: 510;\n  color: #8E8E93;\n}\n\n.actionsItemActive > span {\n  color: #007AFF;\n}\n\n.actionsItemActive > svg > path {\n  fill: #007AFF;\n}\n\n@media (max-width: 375px) {\n  .actions {\n      max-width: 280px;\n  }\n}\n\n@media (max-width: 345px) {\n  .actions {\n      max-width: 250px;\n  }\n}\n\nmain {\n  padding: 0 16px 0 16px;\n}\n\n\n.wrapperReg > main {\n  padding: 0 16px !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `wgmgavlakWt3EkdWzPV5`,
	"content": `EZvbHgWjGmuoAxNh82XA`,
	"actions": `WeTydePpPhaBu2t0_XbD`,
	"actionsItem": `V1ToqHKL5PV0AvHxp0ZE`,
	"actionsItemActive": `T5MdnY9mkZKK_9YpRtRL`,
	"wrapperReg": `hnB8R0R07BE_9mlYK5ja`
};
export default ___CSS_LOADER_EXPORT___;
