"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useLoadUserData = void 0;
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable max-len */
/* eslint-disable consistent-return */
const ton_core_1 = require("ton-core");
const tonapi_1 = require("../logic/tonapi");
const tokens_1 = require("../mocks/tokens");
const useLoadUserData = () => {
    const tonapi = new tonapi_1.TonApi();
    async function getJettons(address) {
        try {
            // setTokensLoading(true)
            const res = await tonapi.getAllJettonsBalances(address);
            console.log('res getAllJettonsBalances', res);
            const tokenAddressesArr = tokens_1.tokensData?.map(item => item?.tokenAddress[0]);
            const tonInfo = await tonapi.getHumanFriendlyInfo(address);
            const tokenAddresses = res?.balances?.map(el => el?.jetton?.address);
            const missingAddresses = tokenAddressesArr.filter(addr => !tokenAddresses?.includes(addr));
            const allTokenAddresses = [...tokenAddresses ?? '', ...missingAddresses];
            const priceRes = await tonapi.getTokenPrice(allTokenAddresses, 'usd');
            const tonPrice = await tonapi.getTokenPrice('ton', 'usd');
            const price = {
                price: tonPrice?.rates?.TON?.prices?.USD,
                diff: tonPrice?.rates?.TON?.diff_24h?.USD
            };
            const updatedTokens = tokens_1.tokensData?.map((el) => {
                if (el.token === 'TON') {
                    return {
                        ...el,
                        amount: (0, ton_core_1.fromNano)(tonInfo?.balance ?? 0),
                        priceUSD: price.price,
                        priceDiff: price.diff,
                        amountUSD: (price.price * Number((0, ton_core_1.fromNano)(tonInfo?.balance ?? 0).toString())).toFixed(2)
                    };
                }
                // eslint-disable-next-line no-prototype-builtins
                const matchingAddresses = tokenAddressesArr?.filter(el3 => priceRes?.rates?.hasOwnProperty(el3));
                // eslint-disable-next-line no-restricted-syntax
                for (const matchingAddress of matchingAddresses) {
                    if (el.tokenAddress[0] === matchingAddress) {
                        const matchingBalance = priceRes?.rates[matchingAddress]?.prices?.USD;
                        const diffRate = priceRes?.rates[matchingAddress]?.diff_24h?.USD;
                        const tokenPrice = res?.balances.find(el4 => el4?.jetton?.address === matchingAddress);
                        return {
                            ...el,
                            amount: String((Number(tokenPrice?.balance ?? 0) / (10 ** Number(tokenPrice?.jetton?.decimals ?? 0)))),
                            // fromNano(tokenPrice?.balance ?? 0),
                            priceUSD: matchingBalance,
                            priceDiff: diffRate,
                            amountUSD: (parseFloat((Number(tokenPrice?.balance ?? 0) / (10 ** Number(tokenPrice?.jetton?.decimals ?? 0))).toString())
                                * parseFloat(matchingBalance)).toFixed(2),
                            decimals: tokenPrice?.jetton?.decimals
                        };
                    }
                }
                return el;
            });
            const additionalTokens = res?.balances
                ?.filter(el => el?.jetton?.address
                && !tokenAddressesArr?.includes(el?.jetton.address ?? '')
                && !updatedTokens.some(updatedToken => updatedToken.tokenAddress[0] === el?.jetton?.address))
                .map((el) => {
                const additionalTokenPrice = priceRes?.rates[el?.jetton?.address ?? '']?.prices?.USD ?? '0';
                const diffRate = priceRes?.rates[el?.jetton?.address ?? '']?.diff_24h?.USD;
                return {
                    token: el?.jetton?.symbol ?? '',
                    tokenLogo: el?.jetton?.image ?? '',
                    amount: String((Number(el?.balance ?? 0) / (10 ** Number(el?.jetton?.decimals ?? 0)))),
                    priceDiff: diffRate,
                    priceUSD: additionalTokenPrice,
                    amountUSD: (parseFloat((Number(el?.balance ?? 0) / (10 ** Number(el?.jetton?.decimals ?? 0))).toString())
                        * parseFloat(additionalTokenPrice)).toFixed(2),
                    tokenAddress: [el?.jetton?.address ?? ''],
                    decimals: el?.jetton?.decimals
                };
            }) || [];
            const finalUpdatedTokens = [...updatedTokens, ...additionalTokens];
            const finalTotalBalance = finalUpdatedTokens.reduce((acc, el) => acc + parseFloat(el.amountUSD), 0).toFixed(2);
            // setTotalBalance(finalUpdatedTokens.reduce((acc, el) => acc + parseFloat(el.amountUSD), 0).toFixed(2))
            // setTokensData(finalUpdatedTokens as AssetType[])
            // setWalletBalanceTON(
            //     (Math.floor(price.price * Number(fromNano(tonInfo?.balance ?? 0).toString()) * 100) / 100).toFixed(2)
            // )
            const finalTonBalance = (Math.floor(price.price * Number((0, ton_core_1.fromNano)(tonInfo?.balance ?? 0).toString()) * 100) / 100).toFixed(2);
            return {
                tokensData: finalUpdatedTokens,
                totalBalance: finalTotalBalance,
                tonBalance: finalTonBalance,
                tonPriceUsd: price.price
            };
        }
        catch (error) {
            console.error('Error fetching jettons:', error);
        }
    }
    return { getJettons };
};
exports.useLoadUserData = useLoadUserData;
