// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.z97UVnmSHaQpgrVNSsHw {
    border-radius: 20px;
    background: rgba(var(--tertiary-bg-dark), 0.30);
    backdrop-filter: blur(40px);
    -webkit-backdrop-filter: blur(40px); 
    padding: 12px 16px 12px 16px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  
  .tv4rghIcnWDO_sWx5HBb {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 6px;
    flex: 1;
  }
  
  .pXm1jrAkUko0KbCss8cz {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 133.333% */
    letter-spacing: -0.5px;
    color: rgb(var(--placeholder-tertiary));
  }
  
  .lOz37EkHxblq7OlVm0QX {
    width: 100%;
    background: transparent;
    border: none;
    color: rgb(var(--placeholder-primary));
    max-width: 100%;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.5px;
  
    caret-color: rgb(var(--accent-brand)); 
  
    outline: none;
  }
  .lOz37EkHxblq7OlVm0QX::placeholder {
    color: rgb(var(--placeholder-tertiary))
  }
  
  .lOz37EkHxblq7OlVm0QX::-ms-reveal,
  .lOz37EkHxblq7OlVm0QX::-ms-clear {
    display: none;
  }
  
  .DU4xg6lGF8hw399PISWg {
    background: transparent;
  }`, "",{"version":3,"sources":["webpack://./src/components/passwordInput/passwordInput.module.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,+CAA+C;IAC/C,2BAA2B;IAC3B,mCAAmC;IACnC,4BAA4B;IAC5B,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,mBAAmB;EACrB;;EAEA;IACE,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,QAAQ;IACR,OAAO;EACT;;EAEA;IACE,eAAe;IACf,kBAAkB;IAClB,gBAAgB;IAChB,iBAAiB,EAAE,aAAa;IAChC,sBAAsB;IACtB,uCAAuC;EACzC;;EAEA;IACE,WAAW;IACX,uBAAuB;IACvB,YAAY;IACZ,sCAAsC;IACtC,eAAe;IACf,eAAe;IACf,kBAAkB;IAClB,gBAAgB;IAChB,iBAAiB;IACjB,sBAAsB;;IAEtB,qCAAqC;;IAErC,aAAa;EACf;EACA;IACE;EACF;;EAEA;;IAEE,aAAa;EACf;;EAEA;IACE,uBAAuB;EACzB","sourcesContent":[".passwordInner {\n    border-radius: 20px;\n    background: rgba(var(--tertiary-bg-dark), 0.30);\n    backdrop-filter: blur(40px);\n    -webkit-backdrop-filter: blur(40px); \n    padding: 12px 16px 12px 16px;\n    display: flex;\n    flex-direction: row;\n    justify-content: space-between;\n    align-items: center;\n  }\n  \n  .passwordWrapper {\n    display: flex;\n    flex-direction: column;\n    align-items: flex-start;\n    gap: 6px;\n    flex: 1;\n  }\n  \n  .passwordLabel {\n    font-size: 12px;\n    font-style: normal;\n    font-weight: 400;\n    line-height: 16px; /* 133.333% */\n    letter-spacing: -0.5px;\n    color: rgb(var(--placeholder-tertiary));\n  }\n  \n  .passwordInput {\n    width: 100%;\n    background: transparent;\n    border: none;\n    color: rgb(var(--placeholder-primary));\n    max-width: 100%;\n    font-size: 16px;\n    font-style: normal;\n    font-weight: 400;\n    line-height: 20px;\n    letter-spacing: -0.5px;\n  \n    caret-color: rgb(var(--accent-brand)); \n  \n    outline: none;\n  }\n  .passwordInput::placeholder {\n    color: rgb(var(--placeholder-tertiary))\n  }\n  \n  .passwordInput::-ms-reveal,\n  .passwordInput::-ms-clear {\n    display: none;\n  }\n  \n  .eyeBtn {\n    background: transparent;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"passwordInner": `z97UVnmSHaQpgrVNSsHw`,
	"passwordWrapper": `tv4rghIcnWDO_sWx5HBb`,
	"passwordLabel": `pXm1jrAkUko0KbCss8cz`,
	"passwordInput": `lOz37EkHxblq7OlVm0QX`,
	"eyeBtn": `DU4xg6lGF8hw399PISWg`
};
export default ___CSS_LOADER_EXPORT___;
