// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Do_sHL7bmndu28UdH0KS {
    width: 100vw;
    max-width: 920px;
    padding: 12px 24px 20px 24px;
    border-radius: 24px 24px 0px 0px;
    background: rgb(var(--secondary-bg));
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    z-index: 300;
  }
.TLLnWJizjsXFy7iPk3H9 {
    width: 72px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4px;
    background: transparent;
    padding: 0;
    border: none;
    border-radius: 12px;
    box-shadow: none;
    padding: 4px;
}

.TLLnWJizjsXFy7iPk3H9:active {
    background: rgba(var(--placeholder-tertiary), 0.2);
}

/* .navElement:hover {
    background: rgba(var(--placeholder-tertiary), 0.2);
} */

.IiCCFuaM5EYyy99NdJoU {
    color: rgb(var(--placeholder-primary));
}
.IiCCFuaM5EYyy99NdJoU svg path {
    stroke: rgb(var(--placeholder-primary));
}
.dQXUB59HTYMSm4LyB92R {
    color: rgb(var(--placeholder-secondary));
}
.dQXUB59HTYMSm4LyB92R svg path {
    stroke: rgb(var(--placeholder-secondary));
}


.kHudac975AwUJYVIowCJ {
    font-size: 11px;
    font-style: normal;
    font-weight: 510;
    line-height: 13px;
}

.hLYe0_S19FqiCP3Ib92k {
    display: none;
    background: transparent;
}`, "",{"version":3,"sources":["webpack://./src/components/navFooter/navFooter.module.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,gBAAgB;IAChB,4BAA4B;IAC5B,gCAAgC;IAChC,oCAAoC;IACpC,eAAe;IACf,SAAS;IACT,SAAS;IACT,2BAA2B;;IAE3B,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,YAAY;EACd;AACF;IACI,WAAW;IACX,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,QAAQ;IACR,uBAAuB;IACvB,UAAU;IACV,YAAY;IACZ,mBAAmB;IACnB,gBAAgB;IAChB,YAAY;AAChB;;AAEA;IACI,kDAAkD;AACtD;;AAEA;;GAEG;;AAEH;IACI,sCAAsC;AAC1C;AACA;IACI,uCAAuC;AAC3C;AACA;IACI,wCAAwC;AAC5C;AACA;IACI,yCAAyC;AAC7C;;;AAGA;IACI,eAAe;IACf,kBAAkB;IAClB,gBAAgB;IAChB,iBAAiB;AACrB;;AAEA;IACI,aAAa;IACb,uBAAuB;AAC3B","sourcesContent":[".navigation {\n    width: 100vw;\n    max-width: 920px;\n    padding: 12px 24px 20px 24px;\n    border-radius: 24px 24px 0px 0px;\n    background: rgb(var(--secondary-bg));\n    position: fixed;\n    bottom: 0;\n    left: 50%;\n    transform: translateX(-50%);\n\n    display: flex;\n    flex-direction: row;\n    justify-content: space-between;\n    z-index: 300;\n  }\n.navElement {\n    width: 72px;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    gap: 4px;\n    background: transparent;\n    padding: 0;\n    border: none;\n    border-radius: 12px;\n    box-shadow: none;\n    padding: 4px;\n}\n\n.navElement:active {\n    background: rgba(var(--placeholder-tertiary), 0.2);\n}\n\n/* .navElement:hover {\n    background: rgba(var(--placeholder-tertiary), 0.2);\n} */\n\n.navElementActive {\n    color: rgb(var(--placeholder-primary));\n}\n.navElementActive svg path {\n    stroke: rgb(var(--placeholder-primary));\n}\n.navElementNonActive {\n    color: rgb(var(--placeholder-secondary));\n}\n.navElementNonActive svg path {\n    stroke: rgb(var(--placeholder-secondary));\n}\n\n\n.navText {\n    font-size: 11px;\n    font-style: normal;\n    font-weight: 510;\n    line-height: 13px;\n}\n\n.emptry {\n    display: none;\n    background: transparent;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"navigation": `Do_sHL7bmndu28UdH0KS`,
	"navElement": `TLLnWJizjsXFy7iPk3H9`,
	"navElementActive": `IiCCFuaM5EYyy99NdJoU`,
	"navElementNonActive": `dQXUB59HTYMSm4LyB92R`,
	"navText": `kHudac975AwUJYVIowCJ`,
	"emptry": `hLYe0_S19FqiCP3Ib92k`
};
export default ___CSS_LOADER_EXPORT___;
