"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useMediaQuery = void 0;
const react_1 = require("react");
const getWindowWidth = () => {
    const { innerWidth: windowWidth } = typeof window !== 'undefined'
        ? window
        : { innerWidth: 0 };
    return { windowWidth };
};
const useWindowWidth = () => {
    const [windowWidth, setWindowWidth] = (0, react_1.useState)(getWindowWidth());
    const handleResize = () => setWindowWidth(getWindowWidth());
    (0, react_1.useEffect)(() => {
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);
    return { windowWidth, handleResize };
};
const useMediaQuery = (maxWidth) => {
    const { windowWidth: { windowWidth }, handleResize } = useWindowWidth();
    const [isMedia, setIsMedia] = (0, react_1.useState)(false);
    (0, react_1.useEffect)(() => {
        if (windowWidth <= maxWidth) {
            setIsMedia(true);
        }
        else {
            setIsMedia(false);
        }
    }, [handleResize, maxWidth, windowWidth]);
    return isMedia;
};
exports.useMediaQuery = useMediaQuery;
