"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DeLabUtils = void 0;
/* eslint-disable @typescript-eslint/no-unused-vars */
const ton_1 = require("ton");
const ton3_1 = require("ton3");
// async function gteDataApi (url: string) {
//     const data = await axios.get(url)
//     return data
// }
// const METADATA_KEYS = { // sha256 hash of keys utf-8 string name
//     name: 0x82a3537ff0dbce7eec35d69edc3a189ee6f17d82f353a553f9aa96cb0be3ce89n,
//     symbol: 0xb76a7ca153c24671658335bbd08946350ffc621fa1c516e7123095d4ffd5c581n,
//     decimals: 0xee80fd2f1e03480e2282363596ee752d7bb27f50776b95086a0279189675923en,
//     description: 0xc9046f7a37ad0ea7cee73355984fa5428982f8b37c8f7bcec91f7ac71a7cd104n,
//     image: 0x6105d6cc76af400325e94d588ce511be5bfdbb73b437dc51eca43917d7a43e3dn
// }
// function delay (ms: number) {
//     return new Promise(resolve => setTimeout(resolve, ms))
// }
class DeLabUtils {
    constructor(options) {
        this._addressContract = options.addressContract;
        this._addressUser = options.addressUser;
        this._addressWallet = '';
        this._addressStaker = '';
        this._balanceJetton = 0;
        this._balanceStaker = 0;
        this._balanceReward = 0;
        this._metadata = undefined;
        this._addressesFarm = undefined;
        this._client = new ton_1.TonClient({ endpoint: options.rpcurl, apiKey: process.env.API_KEY });
    }
    get rpcurl() {
        return this._client.parameters.endpoint;
    }
    get addressWallet() {
        return this._addressWallet;
    }
    get addressStaker() {
        return this._addressStaker;
    }
    get balanceJetton() {
        return this._balanceJetton;
    }
    get addressContract() {
        return this._addressContract;
    }
    get addressUser() {
        return this._addressUser;
    }
    get metadata() {
        return this._metadata;
    }
    get addressesFarm() {
        return this._addressesFarm;
    }
    get balanceStaker() {
        return this._balanceStaker;
    }
    get balanceReward() {
        return this._balanceReward;
    }
    static unwrap(data, cb) {
        if (data.type) {
            console.error(data);
            if (cb)
                cb(data);
            return undefined;
        }
        return data;
    }
    static addressToBoc(address) {
        const addressO = new ton3_1.Address(address);
        const builder = new ton3_1.Builder().storeAddress(addressO);
        return ton3_1.BOC.toBase64Standard(builder.cell());
    }
    static fromNanoToString(n, type = true) {
        // console.log(n)
        const str = ton3_1.Coins.fromNano(n ?? 0).toString();
        let stringAmount = Number(str).toFixed(2);
        if (Number(stringAmount) === 0) {
            stringAmount = Number(str).toFixed(4);
        }
        if (Number(stringAmount) === 0) {
            stringAmount = Number(str).toFixed(6);
        }
        if (Number(stringAmount) === 0) {
            stringAmount = Number(str).toFixed(9);
        }
        if (type)
            stringAmount = Number(stringAmount).toLocaleString();
        return stringAmount;
    }
    static fromNanoToCoin(n) {
        // console.log(n)
        return Number(ton3_1.Coins.fromNano(n ?? 0).toString());
    }
    async balanceTon(addressUser = this._addressUser) {
        const result = await this._client.getContractState(ton_1.Address.parseFriendly(addressUser).address);
        return result.balance.toString();
    }
    async uninitializedAddress(addressWallet = this._addressWallet) {
        const result = await this._client.getContractState(ton_1.Address.parseFriendly(addressWallet).address);
        return result.state === 'active';
    }
}
exports.DeLabUtils = DeLabUtils;
