"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SendTransactionError = void 0;
class SendTransactionError {
    constructor(requestId, code, message, data) {
        this.id = requestId;
        this.error = {
            code,
            message,
            data
        };
    }
}
exports.SendTransactionError = SendTransactionError;
