// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.GNP9NX9GBwm54RiArezU {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  overflow-y: auto;
  height: 100vh;
}

.mPkQg3qMoDnt3_4zsXNV {
  margin-top: 50px;
  display: flex;
  flex-direction: column;
}

.JYIQrG3DFZsjUuoMQYRX {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 24px;
}

.JYIQrG3DFZsjUuoMQYRX img {
  width: 180px;
  height: 180px;
}

.nGJ0pBX0JsJ9XVfVZXOD {
  font-size: 32px;
  font-weight: 540;
  line-height: 120%;
  text-align: center;
  margin-bottom: 16px;
}

.yUD2Pk_qzhDHbOMk5AaB {
  font-size: 16px;
  color: rgb(var(--placeholder-secondary));
  text-align: center;
  margin-bottom: 20px;
}

.GNP9NX9GBwm54RiArezU > button {
  padding: 14px;
  width: 100%;
  border-radius: 12px;
  background: rgb(var(--accent-brand));
  border: none;
  outline: none;
  color: rgb(var(--placeholder-primary));
  max-width: 360px;
  margin: 0 auto;
  cursor: pointer;
  transition: background 0.3s ease 0s;
}

.GNP9NX9GBwm54RiArezU > button:hover {
  background: rgba(var(--accent-brand), 0.8);
}`, "",{"version":3,"sources":["webpack://./src/pages/redirect-to-telegram/redirect-to-telegram.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,sBAAsB;EACtB,gBAAgB;EAChB,aAAa;AACf;;AAEA;EACE,gBAAgB;EAChB,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,YAAY;EACZ,aAAa;AACf;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,kBAAkB;EAClB,mBAAmB;AACrB;;AAEA;EACE,eAAe;EACf,wCAAwC;EACxC,kBAAkB;EAClB,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,WAAW;EACX,mBAAmB;EACnB,oCAAoC;EACpC,YAAY;EACZ,aAAa;EACb,sCAAsC;EACtC,gBAAgB;EAChB,cAAc;EACd,eAAe;EACf,mCAAmC;AACrC;;AAEA;EACE,0CAA0C;AAC5C","sourcesContent":[".inner {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  flex-direction: column;\n  overflow-y: auto;\n  height: 100vh;\n}\n\n.innerBody {\n  margin-top: 50px;\n  display: flex;\n  flex-direction: column;\n}\n\n.innerImg {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  margin-bottom: 24px;\n}\n\n.innerImg img {\n  width: 180px;\n  height: 180px;\n}\n\n.innerTitle {\n  font-size: 32px;\n  font-weight: 540;\n  line-height: 120%;\n  text-align: center;\n  margin-bottom: 16px;\n}\n\n.innerText {\n  font-size: 16px;\n  color: rgb(var(--placeholder-secondary));\n  text-align: center;\n  margin-bottom: 20px;\n}\n\n.inner > button {\n  padding: 14px;\n  width: 100%;\n  border-radius: 12px;\n  background: rgb(var(--accent-brand));\n  border: none;\n  outline: none;\n  color: rgb(var(--placeholder-primary));\n  max-width: 360px;\n  margin: 0 auto;\n  cursor: pointer;\n  transition: background 0.3s ease 0s;\n}\n\n.inner > button:hover {\n  background: rgba(var(--accent-brand), 0.8);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inner": `GNP9NX9GBwm54RiArezU`,
	"innerBody": `mPkQg3qMoDnt3_4zsXNV`,
	"innerImg": `JYIQrG3DFZsjUuoMQYRX`,
	"innerTitle": `nGJ0pBX0JsJ9XVfVZXOD`,
	"innerText": `yUD2Pk_qzhDHbOMk5AaB`
};
export default ___CSS_LOADER_EXPORT___;
