"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TokenWallet = void 0;
/* eslint-disable @typescript-eslint/no-unused-vars */
// eslint-disable-next-line import/no-extraneous-dependencies
const ton_1 = require("@ton/ton");
const sdk_1 = require("@dedust/sdk");
// import { Address as AdressCore, beginCell, Cell as CellCore, ContractProvider, Sender, SendMode, toNano as toNanoCore } from '@ton/core'
const WALLET_OP = {
    burn_query: 0x3a3b4252,
    transfer_query: 0xf8a7ea5,
    bouncable_transfer_query: 0x3a81b46
};
class TokenWallet {
    static newQueryId() {
        return ~~(Date.now() / 1000);
    }
    static buildTransferMessage(options) {
        const { queryId = this.newQueryId(), tokenAmount, to, responseAddress, fwdAmount = (0, ton_1.toNano)(0.01)
        // fwdBody = new Builder().cell()
         } = options;
        const op = WALLET_OP.transfer_query;
        // transfer_query or bouncable_transfer_query
        const body = new ton_1.Builder()
            .storeUint(op, 32) // op
            .storeUint(queryId, 64) // query_id
            .storeCoins(tokenAmount) // token_amount
            .storeAddress(to) // to_address
            .storeAddress(responseAddress) // response_address
            .storeBit(0) // custom_payload:(Maybe ^Cell)
            .storeCoins(fwdAmount); // fwd_amount
        const fwdBody = options.comment
            ? new ton_1.Builder().storeUint(0, 32).storeStringRefTail(options.comment ?? '').endCell()
            : new ton_1.Builder().endCell();
        if (body.bits + fwdBody.bits.length > 1023) {
            body.storeBit(1).storeRef(fwdBody);
        }
        else {
            body.storeBit(0).storeSlice(fwdBody.asSlice());
        }
        return body.asCell();
    }
    // public static buildNFTTransferMessage (addressTo: string, addressUser: string, payloadComment?: string): Cell {
    //     // transfer_query or bouncable_transfer_query
    //     const queryId = this.newQueryId()
    //     const body = new Builder()
    //         .storeUint(0x5fcc3d14, 32)
    //         .storeUint(queryId || 0, 64)
    //         .storeAddress(Address.parse(addressTo)) // new_owner_address
    //         .storeAddress(Address.parse(addressUser)) // excessesAddress
    //         .storeBit(false)
    //         .storeCoins(1n)
    //         .storeStringRefTail('')
    //         // .storeInt(0, 1) // empty forward_payload
    //     return body.asCell()
    // }
    static buildNFTTransferMessage(addressTo, addressUser, commentPayload) {
        // transfer_query or bouncable_transfer_query
        const queryId = this.newQueryId();
        const body = new ton_1.Builder()
            .storeUint(0x5fcc3d14, 32)
            .storeUint(queryId || 0, 64)
            .storeAddress(ton_1.Address.parse(addressTo)) // new_owner_address
            .storeAddress(ton_1.Address.parse(addressUser)) // excessesAddress
            .storeBit(false)
            .storeCoins(1n);
        const fwdBody = commentPayload
            ? new ton_1.Builder().storeUint(0, 32).storeStringRefTail(commentPayload ?? '').endCell()
            : new ton_1.Builder().endCell();
        if (body.bits + fwdBody.bits.length > 1023) {
            body.storeBit(1).storeRef(fwdBody);
        }
        else {
            body.storeBit(0).storeSlice(fwdBody.asSlice());
        }
        // .storeUint(0x5fcc3d14, 32)
        // .storeUint(createNftTransferBodyParams.queryId || 0, 64)
        // .storeAddress(tonAddress(createNftTransferBodyParams.newOwnerAddress))
        // .storeAddress(tonAddress(createNftTransferBodyParams.excessesAddress))
        // .storeBit(false)
        // .storeCoins(createNftTransferBodyParams.forwardAmount ?? 1n)
        // .storeMaybeRef(this.prepareForwardBody(createNftTransferBodyParams.forwardBody))
        // .endCell();
        return body.asCell();
    }
    static packSwapStep({ poolAddress, limit, next }) {
        const res = new ton_1.Builder()
            .storeAddress(poolAddress)
            .storeUint(0, 1) // reserved
            .storeCoins(limit ?? 0n)
            .storeMaybeRef(next ? this.packSwapStep(next) : null)
            .endCell();
        return res;
    }
    static packSwapParams({ deadline, recipientAddress, referralAddress, fulfillPayload, rejectPayload }) {
        const res = new ton_1.Builder()
            .storeUint(deadline ?? 0, 32)
            .storeAddress(recipientAddress ?? null)
            .storeAddress(referralAddress ?? null)
            .storeMaybeRef(fulfillPayload)
            .storeMaybeRef(rejectPayload)
            .endCell();
        return res;
    }
    static buildSwapMessageTonToJetton({ amount, poolAddress, queryId, limit, swapParams, next }) {
        const body = new ton_1.Builder()
            .storeUint(sdk_1.VaultNative.SWAP, 32)
            .storeUint(queryId ?? 0, 64)
            .storeCoins(amount)
            .storeAddress(poolAddress)
            .storeUint(0, 1)
            .storeCoins(limit ?? 0)
            .storeMaybeRef(next ? this.packSwapStep(next) : null)
            .storeRef(this.packSwapParams(swapParams ?? {}))
            .endCell();
        return body;
    }
    static buildSwapMessageJettonWallet({ amount, queryId, destination, responseAddress, customPayload, forwardAmount, forwardPayload }) {
        const body = new ton_1.Builder()
            .storeUint(sdk_1.JettonWallet.TRANSFER, 32)
            .storeUint(queryId ?? 0, 64)
            .storeCoins(amount)
            .storeAddress(destination)
            .storeAddress(responseAddress)
            .storeMaybeRef(customPayload)
            .storeCoins(forwardAmount ?? 0)
            .storeMaybeRef(forwardPayload)
            .endCell();
        return body;
    }
}
exports.TokenWallet = TokenWallet;
