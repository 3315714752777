// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Ge18xWHvuV1AyEWxlPYA {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 10px;
  position: relative;
  padding: 10px 0;
  cursor: pointer;
}

.Ge18xWHvuV1AyEWxlPYA::after {
  content: '';
  position: absolute;
  width: calc(100% - 51.1px);
  height: 1px;
  bottom: 0;
  right: 0;
  background: rgba(116, 116, 128, 0.4);
}

.jfR7U6Gv9lJxZsmNZgtH {
  display: flex;
  align-items: center;
  width: 100%;
  gap: 10px;
}

.I2uGZpmQ35Ythff51jjc {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  font-size: 18px;
  font-weight: 760;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.eTdeVvtF1cTb8W4UNY6Q {
  font-size: 17px;
  font-weight: 560;
  color: #000;
  margin-bottom: 2px;
}

.g4nA6st_15CdBkpATPHA {
  font-size: 14px;
  color: #8E8E93;
}

.WEc7Hlm0CBLWS69x2Oxd {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/components/skeletons/history-skeleton/history-skeleton.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,WAAW;EACX,SAAS;EACT,kBAAkB;EAClB,eAAe;EACf,eAAe;AACjB;;AAEA;EACE,WAAW;EACX,kBAAkB;EAClB,0BAA0B;EAC1B,WAAW;EACX,SAAS;EACT,QAAQ;EACR,oCAAoC;AACtC;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,WAAW;EACX,SAAS;AACX;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,eAAe;EACf,gBAAgB;EAChB,WAAW;EACX,aAAa;EACb,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,WAAW;EACX,kBAAkB;AACpB;;AAEA;EACE,eAAe;EACf,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,yBAAyB;EACzB,WAAW;AACb","sourcesContent":[".transItem {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  width: 100%;\n  gap: 10px;\n  position: relative;\n  padding: 10px 0;\n  cursor: pointer;\n}\n\n.transItem::after {\n  content: '';\n  position: absolute;\n  width: calc(100% - 51.1px);\n  height: 1px;\n  bottom: 0;\n  right: 0;\n  background: rgba(116, 116, 128, 0.4);\n}\n\n.transItemLeft {\n  display: flex;\n  align-items: center;\n  width: 100%;\n  gap: 10px;\n}\n\n.transItemLogo {\n  width: 40px;\n  height: 40px;\n  border-radius: 50%;\n  font-size: 18px;\n  font-weight: 760;\n  color: #fff;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n.transItemLeftStatus {\n  font-size: 17px;\n  font-weight: 560;\n  color: #000;\n  margin-bottom: 2px;\n}\n\n.transItemLeftFrom {\n  font-size: 14px;\n  color: #8E8E93;\n}\n\n.transItemRight {\n  display: flex;\n  justify-content: flex-end;\n  width: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"transItem": `Ge18xWHvuV1AyEWxlPYA`,
	"transItemLeft": `jfR7U6Gv9lJxZsmNZgtH`,
	"transItemLogo": `I2uGZpmQ35Ythff51jjc`,
	"transItemLeftStatus": `eTdeVvtF1cTb8W4UNY6Q`,
	"transItemLeftFrom": `g4nA6st_15CdBkpATPHA`,
	"transItemRight": `WEc7Hlm0CBLWS69x2Oxd`
};
export default ___CSS_LOADER_EXPORT___;
