// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.KnFW8zDuEhCPwJBR0V7V {
  display: flex;
  align-items: center;
  gap: 12px;
  cursor: pointer;
  padding: 7px 0;
  position: relative;
  transition: background 0.3s ease 0s;
}


.KnFW8zDuEhCPwJBR0V7V:not(:last-child)::after {
  content: "";
  position: absolute;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 0.2px;
  background: rgba(116, 116, 128, 0.1);
}

.KnFW8zDuEhCPwJBR0V7V:hover .poU2kp02OkWu9UXzCwIG {
  color: rgba(var(--placeholder-tertiary), 0.7);
}

.KnFW8zDuEhCPwJBR0V7V:hover .UNBdgX7zfOeciToO04Ai {
  color: rgba(var(--placeholder-primary), 0.7)
}

.vuZPXhKd4tA0F9_4przs {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.afpiAWMGKgLzgnto95EM {
  object-fit: contain;
  object-position: center;
  border-radius: 50%;
  width: 40px;
  height: 40px;
}

.UNBdgX7zfOeciToO04Ai {
  font-size: 18px;
  font-weight: 540;
}

.poU2kp02OkWu9UXzCwIG {
  font-size: 14px;
  font-weight: 400;
  color: rgb(var(--placeholder-tertiary));
}

.uNO38vzXG9NKtuPQzbaY {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
}

.uNO38vzXG9NKtuPQzbaY svg {
  min-width: 24px !important;
  min-height: 18px !important;
  max-height: 124px !important;
}`, "",{"version":3,"sources":["webpack://./src/components/app-card/app-card.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,SAAS;EACT,eAAe;EACf,cAAc;EACd,kBAAkB;EAClB,mCAAmC;AACrC;;;AAGA;EACE,WAAW;EACX,kBAAkB;EAClB,QAAQ;EACR,SAAS;EACT,WAAW;EACX,aAAa;EACb,oCAAoC;AACtC;;AAEA;EACE,6CAA6C;AAC/C;;AAEA;EACE;AACF;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;AACA;EACE,mBAAmB;EACnB,uBAAuB;EACvB,kBAAkB;EAClB,WAAW;EACX,YAAY;AACd;;AAEA;EACE,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,uCAAuC;AACzC;;AAEA;EACE,WAAW;EACX,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,SAAS;AACX;;AAEA;EACE,0BAA0B;EAC1B,2BAA2B;EAC3B,4BAA4B;AAC9B","sourcesContent":[".card {\n  display: flex;\n  align-items: center;\n  gap: 12px;\n  cursor: pointer;\n  padding: 7px 0;\n  position: relative;\n  transition: background 0.3s ease 0s;\n}\n\n\n.card:not(:last-child)::after {\n  content: \"\";\n  position: absolute;\n  right: 0;\n  bottom: 0;\n  width: 100%;\n  height: 0.2px;\n  background: rgba(116, 116, 128, 0.1);\n}\n\n.card:hover .cardDescription {\n  color: rgba(var(--placeholder-tertiary), 0.7);\n}\n\n.card:hover .cardTitle {\n  color: rgba(var(--placeholder-primary), 0.7)\n}\n\n.imgWrapper {\n  width: 40px;\n  height: 40px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n.cardImg {\n  object-fit: contain;\n  object-position: center;\n  border-radius: 50%;\n  width: 40px;\n  height: 40px;\n}\n\n.cardTitle {\n  font-size: 18px;\n  font-weight: 540;\n}\n\n.cardDescription {\n  font-size: 14px;\n  font-weight: 400;\n  color: rgb(var(--placeholder-tertiary));\n}\n\n.cardBody {\n  width: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  gap: 15px;\n}\n\n.cardBody svg {\n  min-width: 24px !important;\n  min-height: 18px !important;\n  max-height: 124px !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card": `KnFW8zDuEhCPwJBR0V7V`,
	"cardDescription": `poU2kp02OkWu9UXzCwIG`,
	"cardTitle": `UNBdgX7zfOeciToO04Ai`,
	"imgWrapper": `vuZPXhKd4tA0F9_4przs`,
	"cardImg": `afpiAWMGKgLzgnto95EM`,
	"cardBody": `uNO38vzXG9NKtuPQzbaY`
};
export default ___CSS_LOADER_EXPORT___;
