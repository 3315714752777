// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CdJPalfd57TAfTsDGOwz,
.FMO90QnaGvVCR9CCkFJO {
  font-size: 14px;
}

.bXw7jQoGc7wrzcvGYIrz {
  font-size: 15px;
  color: rgb(var(--placeholder-primary));
}

.CdJPalfd57TAfTsDGOwz,
.bXw7jQoGc7wrzcvGYIrz {
  margin-bottom: 16px;
}

.FMO90QnaGvVCR9CCkFJO {
  display: inline-block;
  font-weight: 760;
}
`, "",{"version":3,"sources":["webpack://./src/pages/settings/privacy/privacy.module.css"],"names":[],"mappings":"AAAA;;EAEE,eAAe;AACjB;;AAEA;EACE,eAAe;EACf,sCAAsC;AACxC;;AAEA;;EAEE,mBAAmB;AACrB;;AAEA;EACE,qBAAqB;EACrB,gBAAgB;AAClB","sourcesContent":[".text,\n.bold {\n  font-size: 14px;\n}\n\n.title {\n  font-size: 15px;\n  color: rgb(var(--placeholder-primary));\n}\n\n.text,\n.title {\n  margin-bottom: 16px;\n}\n\n.bold {\n  display: inline-block;\n  font-weight: 760;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"text": `CdJPalfd57TAfTsDGOwz`,
	"bold": `FMO90QnaGvVCR9CCkFJO`,
	"title": `bXw7jQoGc7wrzcvGYIrz`
};
export default ___CSS_LOADER_EXPORT___;
