"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.rawToTon = void 0;
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
const ton3_1 = require("ton3");
function rawToTon(address) {
    if (!address) {
        return '';
    }
    const addr = new ton3_1.Address(address, { bounceable: false }).toString('base64');
    return addr;
}
exports.rawToTon = rawToTon;
