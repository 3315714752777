// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.zKknUSjG1jzhyEE0t4kw {
  padding: 10px 0 8px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  position: relative;
  width: 100%;
  cursor: pointer;
}

.zKknUSjG1jzhyEE0t4kw::before {
  content: '';
  position: absolute;
  width: calc(100% - 51.1px);
  height: 0.1px;
  bottom: 0;
  right: 0;
  background: rgba(116, 116, 128, 0.4);
}

.TN7HDx6GKVIQSnef_t1j {
  display: flex;
  align-items: center;
  gap: 10px;
}

.TN7HDx6GKVIQSnef_t1j > .cvR0jZejQ5Y99yxlJfvp {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.Akt2Ae_CZWm_GiQBiIqs {
  font-size: 17px;
  line-height: 129%;
  letter-spacing: 0.43px;
  color: #000;
}

.k3f7h0YrOXewG35zmn2d {
  color: #8E8E93;
}

.HNWjIeLgpSywJBTCtp6B {
  font-size: 16px;
  line-height: 128%;
  letter-spacing: 0.15px;
  color: #000;
}`, "",{"version":3,"sources":["webpack://./src/components/skeletons/asset-skeleton/asset-skeleton.module.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;EACrB,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,SAAS;EACT,kBAAkB;EAClB,WAAW;EACX,eAAe;AACjB;;AAEA;EACE,WAAW;EACX,kBAAkB;EAClB,0BAA0B;EAC1B,aAAa;EACb,SAAS;EACT,QAAQ;EACR,oCAAoC;AACtC;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,SAAS;AACX;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,kBAAkB;AACpB;;AAEA;EACE,eAAe;EACf,iBAAiB;EACjB,sBAAsB;EACtB,WAAW;AACb;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,eAAe;EACf,iBAAiB;EACjB,sBAAsB;EACtB,WAAW;AACb","sourcesContent":[".item {\n  padding: 10px 0 8px 0;\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  gap: 10px;\n  position: relative;\n  width: 100%;\n  cursor: pointer;\n}\n\n.item::before {\n  content: '';\n  position: absolute;\n  width: calc(100% - 51.1px);\n  height: 0.1px;\n  bottom: 0;\n  right: 0;\n  background: rgba(116, 116, 128, 0.4);\n}\n\n.itemInfo {\n  display: flex;\n  align-items: center;\n  gap: 10px;\n}\n\n.itemInfo > .img {\n  width: 40px;\n  height: 40px;\n  border-radius: 50%;\n}\n\n.itemToken {\n  font-size: 17px;\n  line-height: 129%;\n  letter-spacing: 0.43px;\n  color: #000;\n}\n\n.itemAmount {\n  color: #8E8E93;\n}\n\n.itemAmountUsd {\n  font-size: 16px;\n  line-height: 128%;\n  letter-spacing: 0.15px;\n  color: #000;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"item": `zKknUSjG1jzhyEE0t4kw`,
	"itemInfo": `TN7HDx6GKVIQSnef_t1j`,
	"img": `cvR0jZejQ5Y99yxlJfvp`,
	"itemToken": `Akt2Ae_CZWm_GiQBiIqs`,
	"itemAmount": `k3f7h0YrOXewG35zmn2d`,
	"itemAmountUsd": `HNWjIeLgpSywJBTCtp6B`
};
export default ___CSS_LOADER_EXPORT___;
