// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.UEY_W1fk3MOODEk5n3L9 {
  background: rgba(66, 75, 86, 0.30);
  backdrop-filter: blur(40px);
  -webkit-backdrop-filter: blur(40px);
  padding: 16px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 16px;
  transition: all 0.3s ease 0s;
}

.UEY_W1fk3MOODEk5n3L9 > svg:first-child {
  min-width: 40px;
  min-height: 40px;
}

.UEY_W1fk3MOODEk5n3L9:hover {
  background: rgba(66, 75, 86, 0.50);
}

.teVSbh5MQLQ2Eol1KFGI {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 10px;
}

.teVSbh5MQLQ2Eol1KFGI > svg {
  min-height: 24px !important;
  min-width: 24px !important;
}

.teVSbh5MQLQ2Eol1KFGI > p {
  font-size: 14px;
  font-weight: 400;
  color: #FFF !important;
}`, "",{"version":3,"sources":["webpack://./src/components/telegram-banner/telegram-banner.module.css"],"names":[],"mappings":"AAAA;EACE,kCAAkC;EAClC,2BAA2B;EAC3B,mCAAmC;EACnC,aAAa;EACb,mBAAmB;EACnB,aAAa;EACb,mBAAmB;EACnB,SAAS;EACT,mBAAmB;EACnB,4BAA4B;AAC9B;;AAEA;EACE,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,kCAAkC;AACpC;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,WAAW;EACX,SAAS;AACX;;AAEA;EACE,2BAA2B;EAC3B,0BAA0B;AAC5B;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,sBAAsB;AACxB","sourcesContent":[".inner {\n  background: rgba(66, 75, 86, 0.30);\n  backdrop-filter: blur(40px);\n  -webkit-backdrop-filter: blur(40px);\n  padding: 16px;\n  border-radius: 20px;\n  display: flex;\n  align-items: center;\n  gap: 16px;\n  margin-bottom: 16px;\n  transition: all 0.3s ease 0s;\n}\n\n.inner > svg:first-child {\n  min-width: 40px;\n  min-height: 40px;\n}\n\n.inner:hover {\n  background: rgba(66, 75, 86, 0.50);\n}\n\n.innerActions {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  width: 100%;\n  gap: 10px;\n}\n\n.innerActions > svg {\n  min-height: 24px !important;\n  min-width: 24px !important;\n}\n\n.innerActions > p {\n  font-size: 14px;\n  font-weight: 400;\n  color: #FFF !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inner": `UEY_W1fk3MOODEk5n3L9`,
	"innerActions": `teVSbh5MQLQ2Eol1KFGI`
};
export default ___CSS_LOADER_EXPORT___;
