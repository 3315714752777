// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BMIdlkhaDO3AqL4zfpYC {
  padding: 14px 21px;
  font-size: 17px;
  font-weight: 590;
  line-height: 129%;
  border-radius: 12px;
  background: #007AFF;
  letter-spacing: 0.63px;
  height: auto;
}

.BMIdlkhaDO3AqL4zfpYC:hover {
  background: #007AFF;
}`, "",{"version":3,"sources":["webpack://./src/components/ui/button/button.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,mBAAmB;EACnB,mBAAmB;EACnB,sBAAsB;EACtB,YAAY;AACd;;AAEA;EACE,mBAAmB;AACrB","sourcesContent":[".button {\n  padding: 14px 21px;\n  font-size: 17px;\n  font-weight: 590;\n  line-height: 129%;\n  border-radius: 12px;\n  background: #007AFF;\n  letter-spacing: 0.63px;\n  height: auto;\n}\n\n.button:hover {\n  background: #007AFF;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": `BMIdlkhaDO3AqL4zfpYC`
};
export default ___CSS_LOADER_EXPORT___;
