"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Main = void 0;
const nanostores_1 = require("nanostores");
class Main {
    constructor(rootStore) {
        this.repository = (0, nanostores_1.atom)({
            isLightTheme: localStorage?.getItem('IS_LIGHT_COLOR_THEME') === 'true' || false,
            showNavigation: false
        });
        this.setIsLightTheme = (payload) => {
            this.repository.set({ ...this.repository.get(), isLightTheme: payload });
        };
        this.setShowNavigation = (payload) => {
            this.repository.set({ ...this.repository.get(), showNavigation: payload });
        };
        this.rootStore = rootStore;
    }
}
exports.Main = Main;
