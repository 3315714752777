"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useNftList = void 0;
const react_1 = require("react");
const tonapi_1 = require("../logic/tonapi");
const useNftList = (addressUser) => {
    const [nftList, setNftList] = (0, react_1.useState)([]);
    const [isLoading, setIsLoading] = (0, react_1.useState)(false);
    const tonapi = new tonapi_1.TonApi();
    const loadNftList = async () => {
        if (!addressUser)
            return;
        try {
            setIsLoading(true);
            const res = await tonapi.getAllNftItems(addressUser, undefined);
            if (!res || res.nft_items.length === 0) {
                return;
            }
            const items = res.nft_items;
            setNftList(items);
        }
        catch (error) {
            console.error('Error loading NFT collections', error);
        }
        finally {
            setIsLoading(false);
        }
    };
    (0, react_1.useEffect)(() => {
        loadNftList();
    }, [addressUser]);
    return { nftList, isLoading, loadNftList };
};
exports.useNftList = useNftList;
