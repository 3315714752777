"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ConnectEventError = void 0;
/* eslint-disable @typescript-eslint/default-param-last */
const protocol_1 = require("@tonconnect/protocol");
const EventID_1 = require("./EventID");
class ConnectEventError {
    constructor(code = protocol_1.CONNECT_EVENT_ERROR_CODES.UNKNOWN_ERROR, message) {
        this.event = 'connect_error';
        this.payload = {
            code,
            message
        };
        this.id = EventID_1.TCEventID.getId();
    }
}
exports.ConnectEventError = ConnectEventError;
