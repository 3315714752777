// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.blQG87yhSx9bmD54MwXK {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 50001;
}

.lo4p8mOCNqeQ2Wh4I3_X {
  background-color: #fff;
  border-radius: 20px 20px 0px 0px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  padding: 20px 20px 0 20px;
  width: 100%;
  overscroll-behavior: contain;
  max-width: 100%;
  position: fixed;
  bottom: 0;
  z-index: 30;
  /* animation: slideIn 0.2s ease-out; */
  width: 470px;
  overflow-y: auto !important;
}

.pwGHwsMHJNaIEjuhdF7f {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 24px;
  line-height: 1;
  transition: color 0.3s ease 0s;
}

.pwGHwsMHJNaIEjuhdF7f:hover {
  color: #1976D2;
}
`, "",{"version":3,"sources":["webpack://./src/components/ui/modal/modal.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,MAAM;EACN,OAAO;EACP,QAAQ;EACR,SAAS;EACT,oCAAoC;EACpC,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,cAAc;AAChB;;AAEA;EACE,sBAAsB;EACtB,gCAAgC;EAChC,2CAA2C;EAC3C,yBAAyB;EACzB,WAAW;EACX,4BAA4B;EAC5B,eAAe;EACf,eAAe;EACf,SAAS;EACT,WAAW;EACX,sCAAsC;EACtC,YAAY;EACZ,2BAA2B;AAC7B;;AAEA;EACE,kBAAkB;EAClB,SAAS;EACT,WAAW;EACX,6BAA6B;EAC7B,YAAY;EACZ,eAAe;EACf,eAAe;EACf,cAAc;EACd,8BAA8B;AAChC;;AAEA;EACE,cAAc;AAChB","sourcesContent":[".modalBackdrop {\n  position: fixed;\n  top: 0;\n  left: 0;\n  right: 0;\n  bottom: 0;\n  background-color: rgba(0, 0, 0, 0.5);\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  z-index: 50001;\n}\n\n.modalContent {\n  background-color: #fff;\n  border-radius: 20px 20px 0px 0px;\n  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);\n  padding: 20px 20px 0 20px;\n  width: 100%;\n  overscroll-behavior: contain;\n  max-width: 100%;\n  position: fixed;\n  bottom: 0;\n  z-index: 30;\n  /* animation: slideIn 0.2s ease-out; */\n  width: 470px;\n  overflow-y: auto !important;\n}\n\n.closeButton {\n  position: absolute;\n  top: 10px;\n  right: 10px;\n  background-color: transparent;\n  border: none;\n  cursor: pointer;\n  font-size: 24px;\n  line-height: 1;\n  transition: color 0.3s ease 0s;\n}\n\n.closeButton:hover {\n  color: #1976D2;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modalBackdrop": `blQG87yhSx9bmD54MwXK`,
	"modalContent": `lo4p8mOCNqeQ2Wh4I3_X`,
	"closeButton": `pwGHwsMHJNaIEjuhdF7f`
};
export default ___CSS_LOADER_EXPORT___;
