"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TCEventID = void 0;
class EventIdService {
    constructor() {
        this.eventIdStorageKey = 'ton-connect-service-event-id';
        this.eventId = 0;
        const storedEventId = localStorage.getItem(this.eventIdStorageKey);
        this.eventId = storedEventId ? Number(storedEventId) : 0;
    }
    getId() {
        this.eventId++;
        localStorage.setItem(this.eventIdStorageKey, String(this.eventId));
        return this.eventId;
    }
}
exports.TCEventID = new EventIdService();
