// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Jr1pUSizXpPPp2ylN5Lt {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.pv7j_VUdCrZSvHAFDMQX {
  margin-bottom: 24px;
}

.ggT9A0goLi6ngaRVZoyY {
  font-size: 32px;
  font-weight: 540;
  letter-spacing: -0.264px;
  color: rgb(var(--placeholder-primary));
  margin-bottom: 24px;
}

.aEuqKGnzJlzQlqfMmHBS {
  display: flex;
  align-items: flex-start;
  gap: 16px;
  margin-bottom: 16px;
}

.aEuqKGnzJlzQlqfMmHBS > p {
  cursor: pointer;
  font-size: 16px;
  color: rgba(var(--placeholder-primary), 0.9);
  letter-spacing: -0.5px;
}

.xKZ82U9xrsTgOS612Q4d {
  margin-top: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 20px;
  min-height: 20px;
  max-height: 20px;
  max-width: 20px;
  border: 2px solid rgb(var(--accent-brand));
  border-radius: 5px;
  cursor: pointer;
}

.xKZ82U9xrsTgOS612Q4d > svg {
  position: relative;
  z-index: 2;
}

.ik7pdbjMq99SnFlOmEhg {
  border: none;
  background: #007aff;
}`, "",{"version":3,"sources":["webpack://./src/pages/reg/security/security.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,wBAAwB;EACxB,sCAAsC;EACtC,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,SAAS;EACT,mBAAmB;AACrB;;AAEA;EACE,eAAe;EACf,eAAe;EACf,4CAA4C;EAC5C,sBAAsB;AACxB;;AAEA;EACE,eAAe;EACf,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,eAAe;EACf,gBAAgB;EAChB,gBAAgB;EAChB,eAAe;EACf,0CAA0C;EAC1C,kBAAkB;EAClB,eAAe;AACjB;;AAEA;EACE,kBAAkB;EAClB,UAAU;AACZ;;AAEA;EACE,YAAY;EACZ,mBAAmB;AACrB","sourcesContent":[".inner {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n}\n\n.innerImg {\n  margin-bottom: 24px;\n}\n\n.innerTitle {\n  font-size: 32px;\n  font-weight: 540;\n  letter-spacing: -0.264px;\n  color: rgb(var(--placeholder-primary));\n  margin-bottom: 24px;\n}\n\n.innerItem {\n  display: flex;\n  align-items: flex-start;\n  gap: 16px;\n  margin-bottom: 16px;\n}\n\n.innerItem > p {\n  cursor: pointer;\n  font-size: 16px;\n  color: rgba(var(--placeholder-primary), 0.9);\n  letter-spacing: -0.5px;\n}\n\n.checkbox {\n  margin-top: 4px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  min-width: 20px;\n  min-height: 20px;\n  max-height: 20px;\n  max-width: 20px;\n  border: 2px solid rgb(var(--accent-brand));\n  border-radius: 5px;\n  cursor: pointer;\n}\n\n.checkbox > svg {\n  position: relative;\n  z-index: 2;\n}\n\n.checkboxChecked {\n  border: none;\n  background: #007aff;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inner": `Jr1pUSizXpPPp2ylN5Lt`,
	"innerImg": `pv7j_VUdCrZSvHAFDMQX`,
	"innerTitle": `ggT9A0goLi6ngaRVZoyY`,
	"innerItem": `aEuqKGnzJlzQlqfMmHBS`,
	"checkbox": `xKZ82U9xrsTgOS612Q4d`,
	"checkboxChecked": `ik7pdbjMq99SnFlOmEhg`
};
export default ___CSS_LOADER_EXPORT___;
