"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NumberRenderer = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const formatNumber_1 = require("../../../../utils/formatNumber");
function scientificToDecimal(numString) {
    const scientific = parseFloat(String(numString));
    const str = scientific.toString();
    if (str.indexOf('e') === -1) {
        return numString;
    }
    const [coefficient, exponent] = str.split('e');
    const decimalPlaces = Math.max(0, -parseInt(exponent, 10));
    return (parseFloat(coefficient) * 10 ** parseInt(exponent, 10)).toFixed(decimalPlaces);
}
const NumberRenderer = ({ value }) => {
    function formatSmallNumber() {
        if (value === 0)
            return value;
        const number = scientificToDecimal(value);
        if (Number(number) > 0.00001) {
            return (0, formatNumber_1.formatNumber)(String(number));
        }
        const parts = number?.toString()?.split('.');
        const [integerPart, decimalPart] = parts;
        let numberOfZeros = 0;
        for (let i = 0; i < decimalPart?.length; i++) {
            if (decimalPart[i] !== '0') {
                break;
            }
            numberOfZeros++;
        }
        const significantDigits = decimalPart?.slice(numberOfZeros)?.slice(0, 3);
        return `${integerPart},0<sub>${numberOfZeros}</sub>${significantDigits}`;
    }
    return ((0, jsx_runtime_1.jsx)("span", { dangerouslySetInnerHTML: { __html: formatSmallNumber() } }));
};
exports.NumberRenderer = NumberRenderer;
