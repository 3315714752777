// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.JF2TjR_c0wnyZfeLBZu4 {
  font-size: 16px;
  font-weight: 540;
  margin-bottom: 5px;
  color: rgb(var(--placeholder-primary));
}

.vJToLfSsTQEkVwnVr9LQ {
  border-radius: 24px;
  padding: 16px;
  background: rgba(var(--secondary-bg), 0.40);
  margin-bottom: 8px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/apps/categories/categories.module.css"],"names":[],"mappings":";AACA;EACE,eAAe;EACf,gBAAgB;EAChB,kBAAkB;EAClB,sCAAsC;AACxC;;AAEA;EACE,mBAAmB;EACnB,aAAa;EACb,2CAA2C;EAC3C,kBAAkB;AACpB","sourcesContent":["\n.allAppsTitle {\n  font-size: 16px;\n  font-weight: 540;\n  margin-bottom: 5px;\n  color: rgb(var(--placeholder-primary));\n}\n\n.allApps {\n  border-radius: 24px;\n  padding: 16px;\n  background: rgba(var(--secondary-bg), 0.40);\n  margin-bottom: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"allAppsTitle": `JF2TjR_c0wnyZfeLBZu4`,
	"allApps": `vJToLfSsTQEkVwnVr9LQ`
};
export default ___CSS_LOADER_EXPORT___;
