"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RootStore = void 0;
/**
 * Store Types
 */
const modules_1 = require("./modules");
/**
 * Root Store Class
 */
class RootStore {
    constructor() {
        this.storeMain = new modules_1.Main(this);
    }
}
exports.RootStore = RootStore;
