// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.J8C1Zy7kMa2R0xMcd53n {
  padding: 16px;
  border-radius: 20px;
  width: 100%;
  outline: none;
  font-size: 16px;
  line-height: 133.33%;
  letter-spacing: -0.24px;
  box-shadow: none;
  border: none;
  background: rgb(var(--secondary-bg));
  color: rgb(var(--placeholder-primary));
}

.J8C1Zy7kMa2R0xMcd53n::placeholder {
  color: rgb(var(--placeholder-tertiary));
}

.y8zFPdcdty4fwuraGVwC {
  position: absolute;
  width: max-content;
  min-width: 200px;
  left: 80px;
  z-index: 15;
  bottom: -98px;
  right: -15px;
  border-radius: 10px;
  overflow-y: auto;
  height: 96px;
  max-height: 96px;
  background: rgb(var(--secondary-bg));
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  padding: 5px 0;
}

.y8zFPdcdty4fwuraGVwC > div {
  font-size: 17.5px;
  cursor: pointer;
  transition: background 0.3s ease 0s;
  padding: 3px 10px;
  color: rgb(var(--placeholder-primary));
  margin-bottom: 3px;
}

.y8zFPdcdty4fwuraGVwC > div:hover {
  background: rgba(var(--placeholder-tertiary), 0.2);
}`, "",{"version":3,"sources":["webpack://./src/components/auto-complete-input/auto-complete-input.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,WAAW;EACX,aAAa;EACb,eAAe;EACf,oBAAoB;EACpB,uBAAuB;EACvB,gBAAgB;EAChB,YAAY;EACZ,oCAAoC;EACpC,sCAAsC;AACxC;;AAEA;EACE,uCAAuC;AACzC;;AAEA;EACE,kBAAkB;EAClB,kBAAkB;EAClB,gBAAgB;EAChB,UAAU;EACV,WAAW;EACX,aAAa;EACb,YAAY;EACZ,mBAAmB;EACnB,gBAAgB;EAChB,YAAY;EACZ,gBAAgB;EAChB,oCAAoC;EACpC,0CAA0C;EAC1C,cAAc;AAChB;;AAEA;EACE,iBAAiB;EACjB,eAAe;EACf,mCAAmC;EACnC,iBAAiB;EACjB,sCAAsC;EACtC,kBAAkB;AACpB;;AAEA;EACE,kDAAkD;AACpD","sourcesContent":[".input {\n  padding: 16px;\n  border-radius: 20px;\n  width: 100%;\n  outline: none;\n  font-size: 16px;\n  line-height: 133.33%;\n  letter-spacing: -0.24px;\n  box-shadow: none;\n  border: none;\n  background: rgb(var(--secondary-bg));\n  color: rgb(var(--placeholder-primary));\n}\n\n.input::placeholder {\n  color: rgb(var(--placeholder-tertiary));\n}\n\n.list {\n  position: absolute;\n  width: max-content;\n  min-width: 200px;\n  left: 80px;\n  z-index: 15;\n  bottom: -98px;\n  right: -15px;\n  border-radius: 10px;\n  overflow-y: auto;\n  height: 96px;\n  max-height: 96px;\n  background: rgb(var(--secondary-bg));\n  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);\n  padding: 5px 0;\n}\n\n.list > div {\n  font-size: 17.5px;\n  cursor: pointer;\n  transition: background 0.3s ease 0s;\n  padding: 3px 10px;\n  color: rgb(var(--placeholder-primary));\n  margin-bottom: 3px;\n}\n\n.list > div:hover {\n  background: rgba(var(--placeholder-tertiary), 0.2);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"input": `J8C1Zy7kMa2R0xMcd53n`,
	"list": `y8zFPdcdty4fwuraGVwC`
};
export default ___CSS_LOADER_EXPORT___;
