"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.smlAddrCheckout = exports.smlAddr2 = exports.smlAddr = void 0;
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
function smlAddr(address) {
    if (!address) {
        return '';
    }
    if (address.endsWith('.ton') || address.endsWith('.me') || address.endsWith('.t.me')) {
        return address;
    }
    return address.slice(0, 5) + '...' + address.slice(address.length - 4, address.length);
}
exports.smlAddr = smlAddr;
function smlAddr2(address) {
    if (!address) {
        return '';
    }
    if (address.endsWith('.ton')) {
        return address;
    }
    return address.slice(0, 4) + '...' + address.slice(address.length - 4, address.length);
}
exports.smlAddr2 = smlAddr2;
function smlAddrCheckout(address) {
    if (!address) {
        return '';
    }
    if (address.endsWith('.ton') || address.endsWith('.me') || address.endsWith('.t.me')) {
        return address;
    }
    const length = address.length;
    const visibleCharacters = Math.min(length, 9);
    return address.slice(0, 2) + '...' + address.slice(length - visibleCharacters, length);
}
exports.smlAddrCheckout = smlAddrCheckout;
