// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NG4scwUvn29Gckgqq6t4 {
  position: relative;
  z-index: 301;
  overflow-x: hidden;
}

.xhdgkhxCdfKmhhTHe2Fq {
  display: flex;
  flex-direction: column;
  min-height: 95%;
  max-height: 100%;
  overflow-y: auto;
  background: rgb(var(--primary-bg));
}

.enk7nR9ljCyDAoLPFTBQ {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.hD7s2yPPA3xAs4YTG7NF {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;
  justify-items: start;
  gap: 5px;
  margin-bottom: 20px;
}

.dO1RjxiFIQc46JekwTrr {
  color: rgb(var(--accent-brand));
  font-size: 16.5px;
  font-weight: 540;
  letter-spacing: 0.43px;
  background: transparent;
  cursor: pointer;
  border: none;
  z-index: 305;
  padding: 4px;
  border-radius: 8px;
  transition: background 0.3s ease 0s;
}

.dO1RjxiFIQc46JekwTrr:active {
  background: rgba(var(--placeholder-tertiary), 0.2);
}

/* .modalTopButton:hover {
  background: rgba(var(--placeholder-tertiary), 0.2);
} */

.O9uWgTapjIl2Vg3LawlP {
  font-size: 17px;
  font-weight: 760;
  letter-spacing: 0.43px;
  line-height: 129.41%;
  margin: 0 auto;
}
`, "",{"version":3,"sources":["webpack://./src/pages/main/transaction-detail/transaction-detail.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,YAAY;EACZ,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,eAAe;EACf,gBAAgB;EAChB,gBAAgB;EAChB,kCAAkC;AACpC;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,YAAY;AACd;;AAEA;EACE,aAAa;EACb,qCAAqC;EACrC,mBAAmB;EACnB,oBAAoB;EACpB,QAAQ;EACR,mBAAmB;AACrB;;AAEA;EACE,+BAA+B;EAC/B,iBAAiB;EACjB,gBAAgB;EAChB,sBAAsB;EACtB,uBAAuB;EACvB,eAAe;EACf,YAAY;EACZ,YAAY;EACZ,YAAY;EACZ,kBAAkB;EAClB,mCAAmC;AACrC;;AAEA;EACE,kDAAkD;AACpD;;AAEA;;GAEG;;AAEH;EACE,eAAe;EACf,gBAAgB;EAChB,sBAAsB;EACtB,oBAAoB;EACpB,cAAc;AAChB","sourcesContent":[".wrapper {\n  position: relative;\n  z-index: 301;\n  overflow-x: hidden;\n}\n\n.modal {\n  display: flex;\n  flex-direction: column;\n  min-height: 95%;\n  max-height: 100%;\n  overflow-y: auto;\n  background: rgb(var(--primary-bg));\n}\n\n.inner {\n  display: flex;\n  flex-direction: column;\n  height: 100%;\n}\n\n.modalTop {\n  display: grid;\n  grid-template-columns: repeat(3, 1fr);\n  align-items: center;\n  justify-items: start;\n  gap: 5px;\n  margin-bottom: 20px;\n}\n\n.modalTopButton {\n  color: rgb(var(--accent-brand));\n  font-size: 16.5px;\n  font-weight: 540;\n  letter-spacing: 0.43px;\n  background: transparent;\n  cursor: pointer;\n  border: none;\n  z-index: 305;\n  padding: 4px;\n  border-radius: 8px;\n  transition: background 0.3s ease 0s;\n}\n\n.modalTopButton:active {\n  background: rgba(var(--placeholder-tertiary), 0.2);\n}\n\n/* .modalTopButton:hover {\n  background: rgba(var(--placeholder-tertiary), 0.2);\n} */\n\n.modalTitle {\n  font-size: 17px;\n  font-weight: 760;\n  letter-spacing: 0.43px;\n  line-height: 129.41%;\n  margin: 0 auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `NG4scwUvn29Gckgqq6t4`,
	"modal": `xhdgkhxCdfKmhhTHe2Fq`,
	"inner": `enk7nR9ljCyDAoLPFTBQ`,
	"modalTop": `hD7s2yPPA3xAs4YTG7NF`,
	"modalTopButton": `dO1RjxiFIQc46JekwTrr`,
	"modalTitle": `O9uWgTapjIl2Vg3LawlP`
};
export default ___CSS_LOADER_EXPORT___;
