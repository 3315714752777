// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.kCqCfJYnXUs9oMdvwP1E {
  display: flex;
  flex-direction: column;
  height: 95vh;
  margin: 10px auto;
  max-width: 600px;
}

.t6UqGWKTyN066ueW_uNs {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.t6UqGWKTyN066ueW_uNs > img {
  max-width: 100%;
  width: 250px;
  height: 250px;
}

.O5YzV8zzKuA6XhrocMh8 {
  font-size: 25px;
  text-align: center;
  margin: 15px 0;
}

.z3cXRWZOJZdD8VCucy6H {
    margin-top: auto;
    font-size: 17px;
    width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/pages/tonconnect/tonconnect.module.css"],"names":[],"mappings":";AACA;EACE,aAAa;EACb,sBAAsB;EACtB,YAAY;EACZ,iBAAiB;EACjB,gBAAgB;AAClB;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,sBAAsB;AACxB;;AAEA;EACE,eAAe;EACf,YAAY;EACZ,aAAa;AACf;;AAEA;EACE,eAAe;EACf,kBAAkB;EAClB,cAAc;AAChB;;AAEA;IACI,gBAAgB;IAChB,eAAe;IACf,WAAW;AACf","sourcesContent":["\n.container {\n  display: flex;\n  flex-direction: column;\n  height: 95vh;\n  margin: 10px auto;\n  max-width: 600px;\n}\n\n.content {\n  height: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  flex-direction: column;\n}\n\n.content > img {\n  max-width: 100%;\n  width: 250px;\n  height: 250px;\n}\n\n.description {\n  font-size: 25px;\n  text-align: center;\n  margin: 15px 0;\n}\n\n.btn {\n    margin-top: auto;\n    font-size: 17px;\n    width: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `kCqCfJYnXUs9oMdvwP1E`,
	"content": `t6UqGWKTyN066ueW_uNs`,
	"description": `O5YzV8zzKuA6XhrocMh8`,
	"btn": `z3cXRWZOJZdD8VCucy6H`
};
export default ___CSS_LOADER_EXPORT___;
