"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StorageWallet = void 0;
class StorageWallet {
    constructor() {
        this._data = window.localStorage;
    }
    save(key, data) {
        try {
            this._data.setItem(`dewallet-${key}`, data);
        }
        catch (error) {
            console.error(error);
            return false;
        }
        return true;
    }
    get(key) {
        try {
            return this._data.getItem(`dewallet-${key}`) ?? this._data.getItem(`wallet-${key}`);
        }
        catch (error) {
            console.error(error);
            return false;
        }
    }
    del(key) {
        try {
            this._data.removeItem(`dewallet-${key}`);
            this._data.removeItem(`wallet-${key}`);
        }
        catch (error) {
            console.error(error);
            return false;
        }
        return true;
    }
}
exports.StorageWallet = StorageWallet;
