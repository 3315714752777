"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.tonConnectDeviceInfo = exports.CURRENT_PROTOCOL_VERSION = exports.MIN_PROTOCOL_VERSION = void 0;
const events_1 = require("../events");
exports.MIN_PROTOCOL_VERSION = 2;
exports.CURRENT_PROTOCOL_VERSION = 2;
exports.tonConnectDeviceInfo = {
    platform: (0, events_1.getPlatform)(),
    appName: 'DeWallet',
    appVersion: '1.2',
    maxProtocolVersion: exports.CURRENT_PROTOCOL_VERSION,
    features: ['SendTransaction', { name: 'SendTransaction', maxMessages: 4 }]
};
