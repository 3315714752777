// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cBgxmEnTHsKoI45inuDY {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cBgxmEnTHsKoI45inuDY > div {
  border-top: 4px solid #007AFF !important;
}`, "",{"version":3,"sources":["webpack://./src/components/spinner/spinner.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,aAAa;EACb,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,wCAAwC;AAC1C","sourcesContent":[".inner {\n  height: 100vh;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n.inner > div {\n  border-top: 4px solid #007AFF !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inner": `cBgxmEnTHsKoI45inuDY`
};
export default ___CSS_LOADER_EXPORT___;
